<template>
  <div>
    <NavBar :fixed="true" :title="$t('mine.serviceTools.message')" left-arrow placeholder @click-left="$router.go(-1)" />
    <JList tabKey="name" list tabVal="value" class="list_wrap" :tabs="tabs" paramKey="status" url="/users/message" ref="listRef"
      tabBottomBorder customList :refresh="refresh">
      <template v-slot:all="row">
        <MSGItem :data="row.data" @read="read" />
      </template>
      <template v-slot:unread="row">
        <MSGItem :data="row.data" @read="read" />
      </template>
    </JList>
  </div>
</template>

<script>
import { jList as JList, br as TextBr } from "@tt/components";
import MSGItem from "./components/messageItem.vue"
import { NavBar } from "vant";
import { READ_NOTICE } from "@tt/api";
export default {
  components: {
    JList,
    TextBr,
    NavBar,
    MSGItem
  },
  data() {
    this.tabs = [
      { name: this.$t("noticeList.all"), value: "all" },
      { name: this.$t("noticeList.unread"), value: "unread" },
    ];
    return {
      refresh: 0,
    };
  },
  methods: {
    async read(item) {
      if(item.reader) return;
      try {
        await READ_NOTICE(item.id);
        this.$refs.listRef.handleRefresh();
      } catch (error) {
        console.error(error, ">>>>");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@tt/components/src/common.css");

.unread_wrap {
  margin-right: calc(12rem / 32);
  width: calc(20rem / 32);
  height: calc(20rem / 32);
  /* background-color: var(--err); */
  border-radius: 50%;
}

@paddingRight: 20px;

.item_wrap {
  padding: 12px @paddingRight;
  // margin-bottom: 12px;
  margin-top: 8px;
  position: relative;

  .title {
    font-weight: bold;
    color: #256dcd !important;
  }
}

.unread {
  &::after {
    content: "";
    display: block;
    @size: 6px;
    width: @size;
    height: @size;
    border-radius: 50%;
    background-color: #f00;
    position: absolute;
    right: @paddingRight;
    top: 50%;
  }
}
</style>
