<template>
  <div @click="$emit('read', data)" class="top1 hidden padding12 bg-fff item_wrap"
    :class="data.reader == 0 ? 'unread' : ''" style="border-bottom: calc(1rem / 32) solid var(--bg)">
    <div class="flex flex-center-ver top8">
      <div class="unread_wrap" v-if="data.reader == 0"></div>
      <div class="font38 title">
        {{ data.title }}
      </div>
    </div>

    <div class="font28 top10 color-707070">
      {{ $format(data.update || data.indate, verConfig.dateFormat || "MM/dd/yyyy HH:mm:ss") }}
    </div>
    <div class="top12 font32">
      <TextBr :text="data.content"></TextBr>
      <!-- {{ data.content }} -->
    </div>
    <div class="top12"></div>
  </div>
</template>

<script>
import { br as TextBr } from "@tt/components";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TextBr
  }
};
</script>

<style lang="less" scoped>
@import url("~@tt/components/src/common.css");
.unread_wrap {
  margin-right: calc(12rem / 32);
  width: calc(20rem / 32);
  height: calc(20rem / 32);
  /* background-color: var(--err); */
  border-radius: 50%;
}

@paddingRight: 20px;

.item_wrap {
  padding: 12px @paddingRight;
  // margin-bottom: 12px;
  margin-top: 8px;
  position: relative;

  .title {
    font-weight: bold;
    color: #256dcd !important;
  }
}

.unread {
  &::after {
    content: "";
    display: block;
    @size: 6px;
    width: @size;
    height: @size;
    border-radius: 50%;
    background-color: #f00;
    position: absolute;
    right: @paddingRight;
    top: 50%;
  }
}
</style>